<template>
  <div>

    <v-row v-if="isLoading"
           justify="center">
      <v-col cols="12" lg="3" v-for="item in 4" :key="item">
        <v-card class="rounded-lg shadow">
          <v-card-text>
            <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>

      <v-col cols="12" lg="4">
        <v-card class="rounded-lg shadow">
          <v-card-text>
            <div class="d-flex justify-space-between align-center">

              <div>
                <v-card-title class="grey--text text--darken-4">
                  {{ CurrencyFormatting(statistiques.vouchers_amount) }} DZD
                </v-card-title>
                <v-card-subtitle class="text-no-wrap">
                  Total vouchers
                </v-card-subtitle>
              </div>

              <div>
                <v-avatar size="60" color="gifty">
                  <v-icon dark size="25">mdi-currency-usd</v-icon>
                </v-avatar>
              </div>

            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="4">
        <v-card class="rounded-lg shadow">
          <v-card-text>
            <div class="d-flex justify-space-between align-center">

              <div>
                <v-card-title class="grey--text text--darken-4">
                  {{ CurrencyFormatting(statistiques.consumed_vouchers_amount) }} DZD
                </v-card-title>
                <v-card-subtitle class="text-no-wrap">
                  Total consomation
                </v-card-subtitle>
              </div>

              <div>
                <v-progress-circular
                    width="4"
                    size="60"
                    :value="statistiques.consumed_vouchers_amount_percent"
                    color="success">
                  <small class="font-weight-medium">
                    {{ parseFloat(statistiques.consumed_vouchers_amount_percent).toFixed(2) || '0.00' }}%
                  </small>
                </v-progress-circular>
              </div>

            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="4">
        <v-card class="rounded-lg shadow">
          <v-card-text>
            <div class="d-flex justify-space-between align-center">

              <div>
                <v-card-title class="grey--text text--darken-4">
                  {{ CurrencyFormatting(statistiques.not_consumed_vouchers_amount) }} DZD
                </v-card-title>
                <v-card-subtitle class="text-no-wrap">
                  Total non consommé
                </v-card-subtitle>
              </div>

              <div>
                <v-progress-circular
                    width="4"
                    size="60"
                    :value="statistiques.not_consumed_vouchers_amount_percent"
                    color="warning">
                  <small class="font-weight-medium">
                    {{ parseFloat(statistiques.not_consumed_vouchers_amount_percent).toFixed(2) || '0.00' }}%
                  </small>
                </v-progress-circular>
              </div>

            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    isLoading() {
      return this.$store.getters.getStatsLoading;
    },
    statistiques() {
      return this.$store.getters.getStatsVouchers;
    },
  },
  mounted() {
  }
}
</script>